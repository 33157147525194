
  import { Component, Vue, Watch } from "vue-property-decorator";
  import { readResources, readResourcesTotal } from "@/store/main/getters";
  import { dispatchSearchResources } from "@/store/main/actions";
  import ResourceCard from "@/components/ResourceCard.vue";
  import { debounce } from "@/utils";

  const debouncedSearch = debounce(dispatchSearchResources, 300);

  @Component({
    components: {
      ResourceCard,
    },
  })
  export default class Resources extends Vue {
    query: string | null = null;
    page = 1;

    get resources() {
      return readResources(this.$store);
    }

    get pages() {
      return Math.ceil(readResourcesTotal(this.$store) / 24);
    }

    @Watch("query")
    async onQueryChanged() {
      this.page = 1;
      await this.updateResources();
    }

    @Watch("page")
    async onPageChanged() {
      await this.updateResources();
    }

    async updateResources() {
      await debouncedSearch(this.$store, { query: this.query, page: this.page });
    }

    public async mounted() {
      this.updateResources();
    }
  }
